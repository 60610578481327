export enum Spec {
  AddToCartQuickView = 'specs.siteSearch.AddToCartQuickView',
  AllTabDisabled = 'specs.siteSearch.AllTabDisabled',
  AppControllerLayoutFix = 'specs.siteSearch.AppControllerLayoutFix',
  Autocomplete = 'specs.siteSearch.Autocomplete',
  ForumFacets = 'specs.siteSearch.ForumFacets',
  ForumTabUpdate = 'specs.siteSearch.ForumTabUpdate',
  HideEmptyTabs = 'specs.siteSearch.HideEmptyTabs',
  ImageResizeOptions = 'specs.siteSearch.ImageResizeOptions',
  Loadable = 'specs.siteSearch.Loadable',
  NewApiURL = 'specs.siteSearch.NewApiURL',
  NewResultsPerPageDefaultValue = 'specs.siteSearch.NewResultsPerPageDefaultValue',
  NewStoresAppDefId = 'specs.siteSearch.NewStoresAppDefId',
  ProGallery = 'specs.siteSearch.ProGallery',
  RemoveDuplicateControllers = 'specs.siteSearch.RemoveDuplicateControllers',
  ScrollToWidget = 'specs.siteSearch.ScrollToWidget',
  SeoHiddenPages = 'specs.siteSearch.SeoHiddenPages',
  SuggestionsNoDelay = 'specs.siteSearch.SuggestionsNoDelay',
  ThunderboltCssOptimization = 'specs.siteSearch.ThunderboltCssOptimization',
  TrendingItems = 'specs.siteSearch.TrendingItems',
  UseWarmupData = 'specs.siteSearch.UseWarmupData',
}
